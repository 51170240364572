export const getInventorizationErrMsg =
  'Ошибка получения детальной информации о поручении на инвентаризацию'

export const getInventorizationsErrMsg = 'Ошибка получения списка поручений на инвентаризацию'
export const createInventorizationErrMsg = 'Ошибка создания поручения на инвентаризацию'
export const completeInventorizationErrMsg = 'Ошибка выполнения поручения на инвентаризацию'

export const getInventorizationEquipmentsErrMsg =
  'Ошибка получения списка оборудования для инвентаризации'

export const getInventorizationEquipmentErrMsg = 'Ошибка получения оборудования для инвентаризации'

export const createInventorizationEquipmentErrMsg =
  'Ошибка добавления оборудования при проведении сверки'

export const updateInventorizationEquipmentErrMsg =
  'Ошибка сохранения оборудования при проведении сверки'
